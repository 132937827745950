body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    font-family: "HelixRegular";
    background-color: #f7f7f7;
  }
  
  @media screen and (min-width: 1280px) and (max-width: 1768px) {
    html {
      font-size: 14px;
    }
  }
  
  @font-face {
    font-family: "HelixRegular";
    src:
      local("HelixRegular"),
      url("./assets/fonts/Hellix-Regular.ttf") format("truetype");
    /* font-weight: 100 900; */
  }
  
  @font-face {
    font-family: "HelixMedium";
    src:
      local("HelixMedium"),
      url("./assets/fonts/Hellix-Medium.ttf") format("truetype");
    /* font-weight: 100 900; */
  }
  @font-face {
    font-family: "HelixSemiBold";
    src:
      local("HelixSemiBold"),
      url("./assets/fonts/Hellix-SemiBold.ttf") format("truetype");
    /* font-weight: 100 900; */
  }
  
  @font-face {
    font-family: "HelixBold";
    src:
      local("HelixBold"),
      url("./assets/fonts/Hellix-Bold.ttf") format("truetype");
    /* font-weight: 100 900; */
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar:hover {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: #fff;
    /* box-shadow: inset 0 0 5px rgba(220, 218, 218, 0.507); */
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(170, 170, 170, 0.5);
    border-radius: 35px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(123, 123, 123, 0.55);
  }
  